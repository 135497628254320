import keyMirror from 'keymirror'

function generateActionTypes(actions) {
  const typeMap = {}
  for (const i in actions) {
    const action = actions[i]
    typeMap[`${action}_REQUEST`] = null
    typeMap[`${action}_SUCCESS`] = null
    typeMap[`${action}_FAILURE`] = null
  }

  return keyMirror(typeMap)
}

export default generateActionTypes([
  'INIT_API',
  'FETCH_MY_APPS',
  'FETCH_APP_DETAIL',
  'FETCH_APP_QUICK_SETTING',
  'FETCH_APP_SETTINGS',
  'SILENTLY_FETCH_APP_SETTINGS',
  'UPLOAD_APP_SETTINGS',
  'SILENTLY_FETCH_APP_DETAIL',
  'FETCH_APP_ITERATION_DETAIL_SILENTLY',
  'FETCH_APP_ITERATION_DETAIL_OPENLY',
  'START_APP_JOB',
  'STOP_APP_JOB',
  'DELETE_APP',
  'APP_SANDBOX_RUN',
  'APP_QUICK_SETTINGS',
  'RESET_APP_SANDBOX_RUN',
  'SAVE_APP_SCHEDULE',
  'FETCH_TEMPLATES',
  'FETCH_RECC_TEMPLATES',
  'FETCH_WELLKNOWN',
  'FETCH_MY_PROFILE',
  'CUSTOM_USER_META',
  'SAVE_DEPLOYMENT_PROFILE',
  'DELETE_DEPLOYMENT_PROFILE',
  'DEPLOY_APP',
  'DELETE_DEPLOY_APP',
  'FETCH_ITR_SAMPLE_DATA',
  'FETCH_ITR_SAMPLE_MODEL',
  'TRIGGER_APP_UNDOCK',
  'FETCH_MY_MODELS',
  'FETCH_MY_DATASETS',
  'FETCH_MY_DATASETS_EMBED',
  'CREATE_FOLDER_DATASETS',
  'CREATE_GROUP_DATASETS',
  'CREATE_DATASET',
  'CREATE_TUS_DATASET',
  'ADD_ITEMS_TO_DATASET_TUS',
  'FETCH_DATASET_DETAIL',
  'FETCH_DATASET_DETAIL_EMBED',
  'FETCH_DATASET_GROUP_DETAIL',
  'SILENTLY_FETCH_DATASET_DETAIL',
  'SILENTLY_FETCH_DATASET_DETAIL_EMBED',
  'ADD_ITEMS_TO_DATASET',
  'FETCH_SETTINGS_CONTENT',
  'BUILD_APP_TEMPLATE',
  'FETCH_DATASET_ITEM_SAMPLE',
  'FETCH_DATASET_SCHEMA',
  'FETCH_MODEL_DETAIL',
  'FETCH_DATASET_ITEM_SCHEMA',
  'FETCH_DATASET_ITEM_ANNOTATION_SUMMARY',
  'DELETE_DSSET',
  'DELETE_DSFOLDER',
  'DELETE_DSITEM',
  'DELETE_DSGROUP',
  'MOVE_DSITEM_GROUP',
  'MOVE_DSFOLDER_FOLDER',
  'MOVE_DSET_FOLDER',
  'EDIT_DSET_SCHEMA',
  'NOTIFICATION_CENTER_ADD_TASK',
  'FETCH_HELP_LINKS',
  'GET_AUTO_MAPPINGS',
  'FETCH_EXPLORE_EXECUTE',
  'FETCH_EXPLORE_PREVIEW',
  'DELETE_DATASET_TUSIO',
  'DATASET_EXISTS_PRECHECK',
  'DATASET_ITEM_EXISTS_PRECHECK',
  'DATASET_DOWNLOAD_PRECHECK'
])

export const SIGNIN_REQUEST = 'SIGNIN_REQUEST'
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS'
export const SIGNIN_FAILURE = 'SIGNIN_FAILURE'

export const SIGNOUT_REQUEST = 'SIGNOUT_REQUEST'
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS'
export const SIGNOUT_FAILURE = 'SIGNOUT_FAILURE'

// Notification
export const ADD_TOAST_NOTIFICATION = 'ADD_TOAST_NOTIFICATION'
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION'

// STATUS WINDOW
export const GET_ITEM_STATUS_LIST = 'GET_ITEM_STATUS_LIST'
export const DELETE_ITEM_STATUS_LIST = 'DELETE_ITEM_STATUS_LIST'
