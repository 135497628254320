import { ADD_TOAST_NOTIFICATION, REMOVE_NOTIFICATION } from './actionTypes'

const initialState = {
  notificationList: []
}

export default function (state = initialState, actions) {
  switch (actions.type) {
    case ADD_TOAST_NOTIFICATION: {
      return {
        ...state,
        notificationList: state.notificationList.concat(actions.payload)
      }
    }
    case REMOVE_NOTIFICATION: {
      return {
        ...state,
        notificationList: state.notificationList.filter(n => n.key !== actions.payload.key)
      }
    }
    default:
      return state
  }
}
