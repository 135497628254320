import websocket from '@giantmachines/redux-websocket'
import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import { apiMiddleware } from 'redux-api-middleware'
import createDebounce from 'redux-debounced'
import logger from 'redux-logger'
import thunk from 'redux-thunk'
import authReducer from './api/redux/authReducer'
import * as notificationCenterReducers from './api/redux/NotificationCenter/reducers'
import notificationReducer from './api/redux/notificationReducer'
import * as apiReducers from './api/redux/reducers'
import statusReducer from './api/redux/statusReducer'

const initialState = {}

const middlewareState = applyMiddleware(createDebounce(), thunk, apiMiddleware, websocket, logger)

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
  combineReducers({
    ...apiReducers,
    ...notificationCenterReducers,
    auth: authReducer,
    notification: notificationReducer,
    bgStatus: statusReducer
  }),
  initialState,
  composeEnhancers(middlewareState)
)

export default store
