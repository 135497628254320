import React from 'react'

class ApiErrorDisplay extends React.Component {
  render() {
    const {
      error: { message }
    } = this.props

    if (message == null) return <span />

    return (
      // <h3 style={{ background: 'red', color: 'yellow', width: '75%', margin: '24px auto', padding: '12px' }}>
      //   Error: {message}
      // </h3>
      <span />
    )
  }
}

export default ApiErrorDisplay
