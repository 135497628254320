import qs from 'qs'
import { RSAA } from 'redux-api-middleware'
import reduxStore from '../store.js'
import actionTypes from './redux/actionTypes.js'

export default class API {
  constructor(config) {
    const { apiRootUrl, wssUrl, fileRootUrl } = config
    this.apiRootUrl = apiRootUrl
    this.wssUrl = wssUrl
    this.fileRootUrl = fileRootUrl
    this.isInitialized = true
  }

  static createInitAPIAction() {
    return {
      type: actionTypes.INIT_API_REQUEST
    }
  }

  createRSAATriplet(prefix) {
    const _name = prefix.toUpperCase()
    const keys = Object.keys(actionTypes)
    const actions = keys.filter(k => k.startsWith(_name))
    console.log('Actions here')
    return actions
  }

  keycloakToken() {
    const currentState = reduxStore.getState()
    const {
      auth: { keycloak, isAuthenticated }
    } = currentState
    if (!isAuthenticated || keycloak == null) return null
    return keycloak.token
  }

  authorizationHeader() {
    var authHeader = {}
    const token = this.keycloakToken()
    if (token != null) {
      authHeader['Authorization'] = `Bearer ${token}`
    }
    return authHeader
  }

  createRSAA(config) {
    var headers =
      config.headers != null ? Object.assign(config.headers, this.authorizationHeader()) : this.authorizationHeader()
    var types = this.createRSAATriplet(config.actionPrefix)

    return {
      [RSAA]: {
        endpoint: `${this.apiRootUrl}${config.endpoint}`,
        method: config.method,
        body: config.body,
        headers,
        types
      }
    }
  }

  createFetchMyProfileRSAA() {
    return this.createRSAA({
      endpoint: '/profile',
      actionPrefix: 'FETCH_MY_PROFILE',
      method: 'GET'
    })
  }

  createFetchHelpLinksRSAA() {
    // var headers = this.authorizationHeader()
    var types = this.createRSAATriplet('FETCH_HELP_LINKS')
    types = types.map(type => {
      return { type: type, meta: {} }
    })
    console.log('types ', types)
    return {
      [RSAA]: {
        endpoint: 'https://storage.googleapis.com/dt-static/docs/datoin-content-index.json',
        method: 'GET',
        types
      }
    }
  }

  createFetchIterDataRSAA(id, iterId) {
    var headers = this.authorizationHeader()
    var types = this.createRSAATriplet('FETCH_ITR_SAMPLE_DATA')
    types = types.map(type => {
      return { type: type, meta: { iterId: iterId } }
    })
    return {
      [RSAA]: {
        endpoint: `${this.apiRootUrl}/data/items/${id}/sample`,
        method: 'GET',
        headers,
        types: types
      }
    }
  }

  createFetchIterModelRSAA(id, iterId) {
    var headers = this.authorizationHeader()
    var types = this.createRSAATriplet('FETCH_ITR_SAMPLE_MODEL')
    types = types.map(type => {
      return { type: type, meta: { iterId: iterId } }
    })
    return {
      [RSAA]: {
        endpoint: `${this.apiRootUrl}/models/${id}/details`,
        method: 'GET',
        headers,
        types: types
      }
    }
  }

  createUserMetaRSAA(params) {
    return this.createRSAA({
      endpoint: `/profile/meta`,
      method: 'POST',
      body: params.body,
      actionPrefix: 'CUSTOM_USER_META',
      headers: { 'Content-Type': 'application/json' }
    })
  }

  createFetchDatoinservicesRSAA() {
    return this.createRSAA({
      endpoint: '/datoinServices',
      actionPrefix: 'FETCH_WELLKNOWN',
      method: 'GET'
    })
  }

  createFetchMyAppsRSAA(params, appendToList) {
    const defaultParams = { start: 0, max: 10 }
    const _params = params != null ? Object.assign(defaultParams, params) : defaultParams
    const queryStr = _params != null ? `?${qs.stringify(_params, { indices: false })}` : ''
    // return this.createRSAA({
    //   endpoint: `/apps${queryStr}`,
    //   actionPrefix: 'FETCH_MY_APPS',
    //   method: 'GET'
    // })

    return {
      [RSAA]: {
        endpoint: `${this.apiRootUrl}/apps${queryStr}`,
        method: 'GET',
        headers: Object.assign(this.authorizationHeader()),
        types: [
          {
            type: 'FETCH_MY_APPS_REQUEST',
            meta: { append: appendToList }
          },
          {
            type: 'FETCH_MY_APPS_SUCCESS',
            meta: { append: appendToList }
          },
          {
            type: 'FETCH_MY_APPS_FAILURE',
            meta: { append: appendToList }
          }
        ]
      }
    }
  }

  createFetchAppDetailRSAA(id, silent) {
    const _prefix = Boolean(silent) ? 'SILENTLY_FETCH_APP_DETAIL' : 'FETCH_APP_DETAIL'
    return this.createRSAA({
      endpoint: `/apps/${id}/details`,
      method: 'GET',
      actionPrefix: _prefix
    })
  }

  createFetchQuickSettingsRSAA(id) {
    const _prefix = 'FETCH_APP_QUICK_SETTING'
    return this.createRSAA({
      endpoint: `/apps/${id}/quickSettings`,
      method: 'GET',
      actionPrefix: _prefix
    })
  }

  createFetchAppIterationDetailRSAA(id, silent) {
    const _prefix = Boolean(silent) ? 'FETCH_APP_ITERATION_DETAIL_SILENTLY' : 'FETCH_APP_ITERATION_DETAIL_OPENLY'
    return this.createRSAA({
      endpoint: `/apps/${id}/iteration`,
      method: 'GET',
      actionPrefix: _prefix
    })
  }

  createStartAppRSAA(id) {
    return this.createRSAA({
      endpoint: `/apps/${id}/start`,
      method: 'POST',
      actionPrefix: 'START_APP_JOB'
    })
  }

  createStopAppRSAA(id) {
    return this.createRSAA({
      endpoint: `/apps/${id}/stop`,
      method: 'POST',
      actionPrefix: 'STOP_APP_JOB'
    })
  }

  deleteAppRSSA(id) {
    return {
      [RSAA]: {
        endpoint: `${this.apiRootUrl}/apps/${id}`,
        method: 'DELETE',
        headers: Object.assign(this.authorizationHeader()),
        types: [
          'DELETE_APP_REQUEST',
          {
            type: 'DELETE_APP_SUCCESS',
            meta: { id: id }
          },
          'DELETE_APP_FAILURE'
        ]
      }
    }
  }

  deleteDSSetRSSA(form_data) {
    return this.createRSAA({
      endpoint: `/data/datasets`,
      method: 'DELETE',
      body: form_data,
      actionPrefix: 'DELETE_DSSET'
      // headers: { 'Content-Type': 'application/json' }
    })
  }

  deleteDSFolderRSSA(form_data, recursive) {
    return this.createRSAA({
      endpoint: `/datFolders?recursive=${recursive}`,
      method: 'DELETE',
      body: form_data,
      actionPrefix: 'DELETE_DSFOLDER'
      // headers: { 'Content-Type': 'application/json' }
    })
  }

  deleteDSItemRSSA(datasetId, form_data) {
    return this.createRSAA({
      endpoint: `/data/${datasetId}/items`,
      method: 'DELETE',
      body: form_data,
      actionPrefix: 'DELETE_DSITEM'
      // headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
  }

  deleteDSGroupRSSA(datasetId, form_data) {
    return this.createRSAA({
      endpoint: `/data/${datasetId}/item/group`,
      method: 'DELETE',
      body: form_data,
      actionPrefix: 'DELETE_DSGROUP'
      // headers: { 'Content-Type': 'application/json' }
    })
  }

  moveDSItemToGroupRSSA(datasetId, form_data, destName) {
    return this.createRSAA({
      endpoint: `/data/${datasetId}/item/group/${destName}`,
      method: 'PUT',
      body: form_data,
      actionPrefix: 'MOVE_DSITEM_GROUP'
      // headers: { 'Content-Type': 'application/json' }
    })
  }

  moveDSFolderToFolderRSSA(form_data) {
    return this.createRSAA({
      endpoint: `/datFolders/move`,
      method: 'PUT',
      body: form_data,
      actionPrefix: 'MOVE_DSFOLDER_FOLDER'
      // headers: { 'Content-Type': 'application/json' }
    })
  }

  moveDSSetToFolderRSSA(form_data) {
    return this.createRSAA({
      endpoint: `/data/move`,
      method: 'PUT',
      body: form_data,
      actionPrefix: 'MOVE_DSET_FOLDER'
      // headers: { 'Content-Type': 'application/json' }
    })
  }

  editDSSetSchemaRSSA(body, id) {
    let js_body = {
      datasetSchema: {
        schema: body
      }
    }
    console.log('body', JSON.stringify(js_body))
    return this.createRSAA({
      endpoint: `/datasets/${id}/schema`,
      method: 'PUT',
      body: JSON.stringify(js_body),
      actionPrefix: 'EDIT_DSET_SCHEMA',
      headers: { 'Content-Type': 'application/json' }
    })
  }

  createSandboxRunRSAA(id, params) {
    return this.createRSAA({
      endpoint: `/apps/${id}/sandbox`,
      method: 'POST',
      body: params.body,
      actionPrefix: 'APP_SANDBOX_RUN',
      headers: { 'Content-Type': 'application/json' }
    })
  }

  createResetSandboxRunAction() {
    return {
      type: actionTypes.RESET_APP_SANDBOX_RUN_REQUEST
    }
  }

  createSaveAppScheduleRSAA(id, params) {
    return this.createRSAA({
      endpoint: `/apps/${id}/schedule`,
      method: 'PUT',
      body: params.body,
      actionPrefix: 'SAVE_APP_SCHEDULE',
      headers: { 'Content-Type': 'application/json' }
    })
  }

  createDeployAppRSAA(params) {
    return this.createRSAA({
      endpoint: `/apps/${params.appId}/deploy`,
      method: 'POST',
      body: params.body,
      actionPrefix: 'DEPLOY_APP',
      headers: { 'Content-Type': 'application/json' }
    })
  }

  createDeleteDeployAppRSAA(params) {
    return this.createRSAA({
      endpoint: `/apps/${params.appId}/deployments/${params.deployId}`,
      method: 'DELETE',
      actionPrefix: 'DELETE_DEPLOY_APP',
      headers: { 'Content-Type': 'application/json' }
    })
  }

  createFetchTemplatesRSAA(params, appendToList) {
    const defaultParams = { start: 0, max: 10 }
    const _params = params != null ? Object.assign(defaultParams, params) : defaultParams
    const queryStr = _params != null ? `?${qs.stringify(_params, { indices: false })}` : ''
    return {
      [RSAA]: {
        endpoint: `${this.apiRootUrl}/templates${queryStr}`,
        method: 'GET',
        headers: Object.assign(this.authorizationHeader()),
        types: [
          {
            type: 'FETCH_TEMPLATES_REQUEST',
            meta: { append: appendToList }
          },
          {
            type: 'FETCH_TEMPLATES_SUCCESS',
            meta: { append: appendToList }
          },
          {
            type: 'FETCH_TEMPLATES_FAILURE',
            meta: { append: appendToList }
          }
        ]
      }
    }
  }

  createFetchReccTemplatesRSAA() {
    return this.createRSAA({
      endpoint: `/recommended-apps`,
      actionPrefix: 'FETCH_RECC_TEMPLATES',
      method: 'GET'
    })
  }

  createSaveDeploymentProfileRSAA(params) {
    const method = params.body.id == null ? 'POST' : 'PUT'
    return this.createRSAA({
      endpoint: '/deployment-profiles',
      method,
      body: params.body,
      actionPrefix: 'SAVE_DEPLOYMENT_PROFILE',
      headers: { 'Content-Type': 'application/json' }
    })
  }
  createDeleteDeploymentProfileRSAA(id) {
    return this.createRSAA({
      endpoint: `/deployment-profiles/${id}`,
      method: 'DELETE',
      actionPrefix: 'DELETE_DEPLOYMENT_PROFILE'
    })
  }

  createTriggerAppUndockRSAA(id) {
    return this.createRSAA({
      endpoint: `/apps/${id}/undock`,
      method: 'POST',
      actionPrefix: 'TRIGGER_APP_UNDOCK'
    })
  }

  createGetUndockStatusRSAA(id) {
    return this.createRSAA({
      endpoint: `/apps/${id}/undock`,
      method: 'GET',
      actionPrefix: 'TRIGGER_APP_UNDOCK'
    })
  }

  createFetchMyModelsRSAA(params) {
    const queryStr = params != null ? `?${qs.stringify(params, { indices: false })}` : ''

    return this.createRSAA({
      endpoint: `/modelSearch${queryStr}`,
      method: 'GET',
      actionPrefix: 'FETCH_MY_MODELS'
    })
  }

  createFetchMyDatasetsRSAA(params) {
    const queryStr = params != null ? `?${qs.stringify(params, { indices: false })}` : ''
    console.log('myDatasets action', queryStr)
    return {
      [RSAA]: {
        endpoint: `${this.apiRootUrl}/data${queryStr}`,
        method: 'GET',
        headers: Object.assign({ 'Content-Type': 'text/plain' }, this.authorizationHeader()),
        types: ['FETCH_MY_DATASETS_REQUEST', 'FETCH_MY_DATASETS_SUCCESS', 'FETCH_MY_DATASETS_FAILURE']
      }
    }
    // this.createRSAA({
    //   endpoint: `/data${queryStr}`,
    //   method: 'GET',
    //   actionPrefix: 'FETCH_MY_DATASETS'
    // })
  }

  createFetchMyDatasetsEmbedRSAA(params) {
    const queryStr = params != null ? `?${qs.stringify(params, { indices: false })}` : ''
    console.log('myDatasets action', queryStr)
    return {
      [RSAA]: {
        endpoint: `${this.apiRootUrl}/data${queryStr}`,
        method: 'GET',
        headers: Object.assign({ 'Content-Type': 'text/plain' }, this.authorizationHeader()),
        types: ['FETCH_MY_DATASETS_EMBED_REQUEST', 'FETCH_MY_DATASETS_EMBED_SUCCESS', 'FETCH_MY_DATASETS_EMBED_FAILURE']
      }
    }
  }

  createCreateDatasetFolderRSAA(params) {
    return this.createRSAA({
      endpoint: `/data/folder`,
      method: 'POST',
      body: params.body,
      actionPrefix: 'CREATE_FOLDER_DATASETS',
      headers: { 'Content-Type': 'application/json' }
    })
  }
  createCreateDatasetGroupRSAA(params) {
    return this.createRSAA({
      endpoint: `/data/${params.id}/item/group`,
      method: 'POST',
      body: params.body,
      actionPrefix: 'CREATE_GROUP_DATASETS',
      headers: { 'Content-Type': 'application/json' }
    })
  }
  createCreateDatasetRSAA(params) {
    return this.createRSAA({
      endpoint: `/data/datasets`,
      method: 'POST',
      body: params.body,
      actionPrefix: 'CREATE_DATASET'
    })
  }

  //Added for TusIO CreateDataSet Method
  createTusCreateDatasetRSAA(params) {
    return this.createRSAA({
      endpoint: `/data/datasets/tus`,
      method: 'POST',
      body: params.body,
      actionPrefix: 'CREATE_TUS_DATASET'
    })
  }

  createTusAddItemsRSAA(id, params) {
    return this.createRSAA({
      endpoint: `/data/${id}/items/tus`,
      method: 'POST',
      body: params.body,
      actionPrefix: 'ADD_ITEMS_TO_DATASET_TUS'
    })
  }

  createFetchDatasetDetailRSAA(id, silent, groupId) {
    const _prefix = Boolean(silent) ? 'SILENTLY_FETCH_DATASET_DETAIL' : 'FETCH_DATASET_DETAIL'
    console.log('id, silent, groupId', id, silent, groupId)
    return {
      [RSAA]: {
        endpoint: groupId
          ? `${this.apiRootUrl}/data/${id}/items?groupName=${groupId}`
          : `${this.apiRootUrl}/data/${id}/items?isNullableGroup=true`,
        method: 'GET',
        headers: Object.assign({ 'Content-Type': 'text/plain' }, this.authorizationHeader()),
        types: ['FETCH_DATASET_DETAIL_REQUEST', 'FETCH_DATASET_DETAIL_SUCCESS', 'FETCH_DATASET_DETAIL_FAILURE']
      }
    }
  }

  createFetchDatasetDetailEmbedRSAA(id, silent, groupId) {
    const _prefix = Boolean(silent) ? 'SILENTLY_FETCH_DATASET_DETAIL_EMBED' : 'FETCH_DATASET_DETAIL_EMBED'
    console.log('id, silent, groupId', id, silent, groupId)
    return {
      [RSAA]: {
        endpoint: groupId
          ? `${this.apiRootUrl}/data/${id}/items?groupName=${groupId}`
          : `${this.apiRootUrl}/data/${id}/items?isNullableGroup=true`,
        method: 'GET',
        headers: Object.assign({ 'Content-Type': 'text/plain' }, this.authorizationHeader()),
        types: [
          'FETCH_DATASET_DETAIL_EMBED_REQUEST',
          'FETCH_DATASET_DETAIL_EMBED_SUCCESS',
          'FETCH_DATASET_DETAIL_EMBED_FAILURE'
        ]
      }
    }
  }

  createFetchDatasetGroupDetailRSAA(id, silent) {
    const _prefix = Boolean(silent) ? 'SILENTLY_FETCH_DATASET_GROUP_DETAIL' : 'FETCH_DATASET_GROUP_DETAIL'

    return this.createRSAA({
      endpoint: `/data/${id}/items`,
      method: 'GET',
      actionPrefix: _prefix
    })
  }

  fetchAppResourcesRSAA(id, silent) {
    const _prefix = Boolean(silent) ? 'SILENTLY_FETCH_APP_SETTINGS' : 'FETCH_APP_SETTINGS'
    return this.createRSAA({
      endpoint: `/apps/${id}/resources`,
      method: 'GET',
      actionPrefix: _prefix
    })
  }

  createFetchSettingsContentRSAA(id, resId) {
    return {
      [RSAA]: {
        endpoint: `${this.apiRootUrl}/apps/${id}/resources/${resId}/raw`,
        method: 'GET',
        headers: Object.assign({ 'Content-Type': 'text/plain' }, this.authorizationHeader()),
        types: [
          'FETCH_SETTINGS_CONTENT_REQUEST',
          'FETCH_SETTINGS_CONTENT_SUCCESS',
          {
            type: 'FETCH_SETTINGS_CONTENT_FAILURE',
            meta: (action, state, res) => {
              if (res) {
                return {
                  status: res.status
                }
              } else {
                return {
                  status: null
                }
              }
            }
          }
        ]
      }
    }
  }

  uploadAppSettingsRSAA(id, form) {
    return this.createRSAA({
      endpoint: `/apps/${id}/resources`,
      method: 'POST',
      body: form,
      actionPrefix: 'UPLOAD_APP_SETTINGS'
    })
  }

  createQuickSettingsRSAA(id, userParentId, params) {
    let endpoint = ''
    if (userParentId !== null) {
      endpoint = `/apps/${id}/qsUpdate?parentId=${userParentId}`
    } else {
      endpoint = `/apps/${id}/qsUpdate`
    }
    return this.createRSAA({
      endpoint: endpoint,
      method: 'POST',
      body: params.body,
      actionPrefix: 'APP_QUICK_SETTINGS',
      headers: { 'Content-Type': 'application/json' }
    })
  }

  createDatasetAddItemsRSAA(id, params) {
    return this.createRSAA({
      endpoint: `/data/${id}/items`,
      method: 'POST',
      body: params.body,
      actionPrefix: 'ADD_ITEMS_TO_DATASET'
    })
  }

  createBuildAppTemplateRSAA(id, params) {
    return this.createRSAA({
      endpoint: `/templates/${id}/build`,
      method: 'POST',
      body: params.body,
      actionPrefix: 'BUILD_APP_TEMPLATE',
      headers: { 'Content-Type': 'application/json' }
    })
  }
  createFetchDatasetItemSampleRSAA(id) {
    return this.createRSAA({
      endpoint: `/data/items/${id}/sample?num=11`,
      method: 'GET',
      actionPrefix: 'FETCH_DATASET_ITEM_SAMPLE',
      headers: { 'Content-Type': 'application/json' }
    })
  }
  createFetchDatasetSchemaRSAA(id) {
    var headers = this.authorizationHeader()
    var types = this.createRSAATriplet('FETCH_DATASET_SCHEMA')
    types = types.map(type => {
      return { type: type, meta: { id: id } }
    })
    return {
      [RSAA]: {
        endpoint: `${this.apiRootUrl}/data/${id}/fresh`,
        method: 'GET',
        headers,
        types: types
      }
    }
  }
  createFetchModelDetailRSAA(id) {
    var headers = this.authorizationHeader()
    var types = this.createRSAATriplet('FETCH_MODEL_DETAIL')
    types = types.map(type => {
      return { type: type, meta: { id: id } }
    })
    return {
      [RSAA]: {
        endpoint: `${this.apiRootUrl}/models/${id}/details`,
        method: 'GET',
        headers,
        types: types
      }
    }
  }

  createFetchUndockRSAA(id) {
    var headers = this.authorizationHeader()
    var types = this.createRSAATriplet('FETCH_APP_UNDOCK')
    types = types.map(type => {
      return { type: type, meta: { id: id } }
    })
    return {
      [RSAA]: {
        endpoint: `${this.apiRootUrl}/apps/${id}/undock`,
        method: 'POST',
        headers,
        types: types
      }
    }
  }

  createFetchDSItemSchemaRSAA(id) {
    return this.createRSAA({
      endpoint: `/data/${id}/details`,
      method: 'GET',
      actionPrefix: 'FETCH_DATASET_ITEM_SCHEMA',
      headers: { 'Content-Type': 'application/json' }
    })
  }

  createFetchDSItemSummaryRSAA(id) {
    return this.createRSAA({
      endpoint: `/pdf/${id}/annotationSummary`,
      method: 'GET',
      actionPrefix: 'FETCH_DATASET_ITEM_ANNOTATION_SUMMARY',
      headers: { 'Content-Type': 'application/json' }
    })
  }

  createDatasetGetAutoMappingRSAA(params) {
    return this.createRSAA({
      endpoint: `/data/mapping`,
      method: 'POST',
      body: JSON.stringify(params.body),
      actionPrefix: 'GET_AUTO_MAPPINGS',
      headers: { 'Content-Type': 'application/json' }
    })
  }

  createDatasetExplorerExecuteRSAA(params) {
    let hasFinalized = params.body.find(item => item.fn === 'FINALIZE') !== undefined
    let actionTypes = [
      'FETCH_EXPLORE_EXECUTE_REQUEST',
      'FETCH_EXPLORE_EXECUTE_SUCCESS',
      'FETCH_EXPLORE_EXECUTE_FAILURE'
    ]
    if (hasFinalized) {
      actionTypes = actionTypes.map(item => {
        return {
          type: item,
          meta: (action, state, res) => {
            return { finalized: true }
          }
        }
      })
    }
    return {
      [RSAA]: {
        endpoint: `${this.apiRootUrl}/data/explore`,
        method: 'POST',
        body: JSON.stringify(params.body),
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        types: actionTypes
      }
    }
  }

  createDatasetExplorerPreviewRSAA(params) {
    return this.createRSAA({
      endpoint: `/data/explore`,
      method: 'POST',
      body: JSON.stringify(params.body),
      actionPrefix: 'FETCH_EXPLORE_PREVIEW',
      headers: { 'Content-Type': 'application/json' }
    })
  }

  createDelteRequestUploadingDataSet(datasetId) {
    return {
      [RSAA]: {
        endpoint: `${this.fileRootUrl}/upload/${datasetId}`,
        method: 'DELETE',
        headers: Object.assign(this.authorizationHeader(), { 'Tus-Resumable': '1.0.0' }),
        types: [
          'DELETE_DATASET_TUSIO_REQUEST',
          {
            type: 'DELETE_DATASET_TUSIO_SUCCESS',
            meta: { id: datasetId }
          },
          'DELETE_DATASET_TUSIO_FAILURE'
        ]
      }
    }
  }

  //Added for TusIO validate Dataset
  createDataSetPreCheck(_params) {
    let actionTypes = [
      'DATASET_EXISTS_PRECHECK_REQUEST',
      'DATASET_EXISTS_PRECHECK_SUCCESS',
      'DATASET_EXISTS_PRECHECK_FAILURE'
    ]
    const queryStr = _params != null ? `?${qs.stringify(_params, { indices: false })}` : ''
    return {
      [RSAA]: {
        endpoint: `${this.apiRootUrl}/upload/dataset/validate${queryStr}`,
        method: 'GET',
        headers: Object.assign(this.authorizationHeader()),
        types: actionTypes
      }
    }
  }

  //Added for TusIO validate  Dataset Item
  createDataSetItemPreCheck(datasetId, params) {
    let actionTypes = [
      'DATASET_ITEM_EXISTS_PRECHECK_REQUEST',
      'DATASET_ITEM_EXISTS_PRECHECK_SUCCESS',
      'DATASET_ITEM_EXISTS_PRECHECK_FAILURE'
    ]
    return {
      [RSAA]: {
        endpoint: `${this.apiRootUrl}/upload/datasetitems/${datasetId}/validate`,
        method: 'POST',
        headers: Object.assign(this.authorizationHeader()),
        body: params.body,
        types: actionTypes
      }
    }
  }

  createDownloadDatasetItemPreCheckSampleRSAA(id) {
    return this.createRSAA({
      endpoint: `/data/items/${id}/validate/size`,
      method: 'GET',
      actionPrefix: 'DATASET_DOWNLOAD_PRECHECK',
      headers: { 'Content-Type': 'application/json' }
    })
  }

  //Added for Sticky Window
  createStatusListOfBgJobs() {
    let actionTypes = ['GET_ITEM_STATUS_LIST_REQUEST', 'GET_ITEM_STATUS_LIST_SUCCESS', 'GET_ITEM_STATUS_LIST_FAILURE']
    return {
      [RSAA]: {
        endpoint: `${this.apiRootUrl}/stickyWindow/tasks?start=0&max=32&sort=updatedOn&sortOrder=desc`,
        method: 'GET',
        headers: Object.assign(this.authorizationHeader()),
        types: actionTypes
      }
    }
  }

  createUpdateStatusListOfBgJobs(_params) {
    let actionTypes = [
      'DELETE_ITEM_STATUS_LIST_REQUEST',
      'DELETE_ITEM_STATUS_LIST_SUCCESS',
      'DELETE_ITEM_STATUS_LIST_FAILURE'
    ]
    return {
      [RSAA]: {
        endpoint: `${this.apiRootUrl}/stickyWindow/tasks`,
        method: 'DELETE',
        body: _params.body,
        headers: Object.assign(this.authorizationHeader()),
        types: actionTypes
      }
    }
  }

  createGenerateCharacteristics(datasetId) {
    let actionTypes = [
      'GENERATE_CHARACTERISTICS_REQUEST',
      'GENERATE_CHARACTERISTICS_SUCCESS',
      'GENERATE_CHARACTERISTICS_FAILURE'
    ]
    return {
      [RSAA]: {
        endpoint: `${this.apiRootUrl}/data/${datasetId}/generateCharacteristics`,
        method: 'GET',
        headers: Object.assign(this.authorizationHeader()),
        types: actionTypes
      }
    }
  }

  //For Cayley Explorer Two
  createDatasetExplorerFetchSampleRSAA(datasetId) {
    let actionTypes = [
      'FETCH_EXPLORE_2_SAMPLE_REQUEST',
      'FETCH_EXPLORE_2_SAMPLE_SUCCESS',
      'FETCH_EXPLORE_2_SAMPLE_FAILURE'
    ]
    return {
      [RSAA]: {
        endpoint: `${this.apiRootUrl}/data/${datasetId}/explore?initConf=false`,
        method: 'GET',
        headers: Object.assign(this.authorizationHeader()),
        types: actionTypes
      }
    }
  }

  createDatasetExplorerExecuteRequestRSAA(datasetId, _params, _addtionalParams) {
    let actionTypes = [
      'FETCH_EXPLORE_2_EXECUTE_REQUEST',
      'FETCH_EXPLORE_2_EXECUTE_SUCCESS',
      'FETCH_EXPLORE_2_EXECUTE_FAILURE'
    ]
    const queryStr = `?${_addtionalParams}`
    return {
      [RSAA]: {
        endpoint: `${this.apiRootUrl}/data/${datasetId}/exploreTwo${queryStr}`,
        method: 'POST',
        body: _params.body,
        headers: Object.assign({ 'Content-Type': 'application/json' }, this.authorizationHeader()),
        types: actionTypes
      }
    }
  }
}
