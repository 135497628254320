import {
  VerticalNav,
  VerticalNavBrand,
  VerticalNavIconBar,
  VerticalNavItem,
  VerticalNavMasthead
} from 'patternfly-react'
import PropTypes from 'prop-types'
import React, { lazy, Suspense } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Redirect, Route, Switch } from 'react-router-dom'
import ApiError from './api/components-examples/APIError'
import { NavDataLoading } from './api/components-examples/utils.js'
import './Dashboard.css'
import './dt-icons.css'
import logo from './images/logo-inline-dark-transparent.png'
import './index.css'

const AppDetail = lazy(() => import('./api/components-examples/AppDetail/index.js'))
const AppStore = lazy(() => import('./api/components-examples/AppStore'))
const DatasetDetail = lazy(() => import('./api/components-examples/DatasetDetail'))
const MyApps = lazy(() => import('./api/components-examples/MyApps'))
const MyDatasets = lazy(() => import('./api/components-examples/MyDatasets'))
const MyModels = lazy(() => import('./api/components-examples/MyModels'))
const SignInRequired = lazy(() => import('./components/SignInRequired'))
const ToastNotification = lazy(() => import('./components/ToastNotification'))
const CayleyExplorer = lazy(() => import('./api/components-examples/CayleyExplorer'))
const Logout = lazy(() => import('./components/Logout'))
const AppStatus = lazy(() => import('./api/components-examples/AppStatus'))
const CayleyExplorer2 = lazy(() => import('./api/components-examples/CayleyExplorer2'))

const VerticalNavigation = props => {
  // FIXME: Use Link from react router instead
  console.log('render activeitem', props.activeItem)
  const navigateTo = path => {
    const { history } = props
    history.push(path)
  }

  return (
    <VerticalNav
      persistentSecondary={false}
      onMenuToggleClick={() => {
        props.onNavCollapse()
      }}
      navCollapsed={props.collapse}
      activePath={props.activeItem && props.activeItem.link}
    >
      <VerticalNavMasthead>
        <VerticalNavBrand titleImg="" iconImg={logo} />
        <VerticalNavIconBar>
          <div className="user-tr-info">
            {props.myProfile ? (
              <span className="text-muted username-tr" style={{ textTransform: 'capitalize' }}>
                <i class="pficon pficon-user" /> {props.myProfile.name}
              </span>
            ) : (
              <span>Hi, User</span>
            )}
            <Suspense fallback={<NavDataLoading />}>
              <Logout />
            </Suspense>
          </div>
        </VerticalNavIconBar>
      </VerticalNavMasthead>
      {props.items.map((item, index) =>
        item.external ? (
          <VerticalNavItem
            key={item.link}
            title={item.title}
            iconClass={item.icon}
            activePath={item.link}
            initialActive={index == 0}
            active={props.activeItem && item.name === props.activeItem.name}
            href={'/' + item.name}
            onClick={() => navigateTo('/' + item.name)}
          />
        ) : (
          <VerticalNavItem
            key={item.link}
            title={item.title}
            iconClass={item.icon}
            activePath={item.link}
            initialActive={index == 0}
            active={props.activeItem && item.name === props.activeItem.name}
            href={item.link}
            onClick={() => navigateTo(item.link)}
          />
        )
      )}
    </VerticalNav>
  )
}

class ExternalLinkIframe extends React.Component {
  componentDidMount() {
    console.log('ExternalLinkIframe mount')
    this.props.collapseNav()
    this.props.collapseAppStatus()
  }

  componentWillUnmount() {
    this.props.openNav()
    this.props.openAppStatus()
  }

  render() {
    const { title, name, history, link, collapseNav } = this.props
    const NewComponent = title.split(' ').join('_').toUpperCase()
    return (
      <NewComponent>
        <section>
          <div className="annotator-modal" />
          <iframe
            style={{ height: 'calc(100vh - 67px)' }}
            frameborder="0"
            id={name === 'annotate' ? 'annoIframe' : ''}
            name={name}
            height="100%"
            width="100%"
            src={link}
          />
        </section>
      </NewComponent>
    )
  }
}

const MainContent = props => {
  const { services, history, collapseNav, openNav, collapseAppStatus, openAppStatus } = props
  return (
    <div className="container-pf-nav-pf-vertical collapsed-nav">
      {/* Move APIError component wherever you want to */}
      <ApiError error={props.error} />
      <Switch>
        <Route
          exact
          path="/apps"
          key="/apps"
          render={props => (
            <Suspense fallback={<NavDataLoading />}>
              {' '}
              <MyApps {...props} />{' '}
            </Suspense>
          )}
        />
        <Route
          exact
          path="/app-store"
          key="/app-store"
          render={props => (
            <Suspense fallback={<NavDataLoading />}>
              {' '}
              <AppStore {...props} />{' '}
            </Suspense>
          )}
        />
        <Route
          exact
          path="/apps/:id"
          key="/apps/:id"
          render={props => (
            <Suspense fallback={<NavDataLoading />}>
              <AppDetail {...props} />{' '}
            </Suspense>
          )}
        />
        <Route
          exact
          path="/models"
          key="/models"
          render={props => (
            <Suspense fallback={<NavDataLoading />}>
              <MyModels {...props} />{' '}
            </Suspense>
          )}
        />
        <Route
          exact
          path="/models/:id"
          key="/models/:id"
          render={props => (
            <Suspense fallback={<NavDataLoading />}>
              <MyModels {...props} />{' '}
            </Suspense>
          )}
        />
        <Route
          exact
          path="/datasets"
          key="/datasets"
          render={props => (
            <Suspense fallback={<NavDataLoading />}>
              <MyDatasets {...props} />{' '}
            </Suspense>
          )}
        />

        <Route
          exact
          path="/datasets/explorer"
          key="/datasets/explorer"
          render={props => {
            return (
              <Suspense fallback={<NavDataLoading />}>
                <CayleyExplorer history={history} collapseNav={collapseNav} openNav={openNav} {...props} />
              </Suspense>
            )
          }}
        />

        <Route exact path="/datasets/:id" key="/datasets/:id" component={MyDatasets} />

        <Route
          exact
          path="/datasets/explorer/:id"
          key="/datasets/explorer/:id"
          render={props => {
            return (
              <Suspense fallback={<NavDataLoading />}>
                <CayleyExplorer history={history} collapseNav={collapseNav} openNav={openNav} {...props} />
              </Suspense>
            )
          }}
        />

        <Route
          exact
          path="/datasets/explorer2"
          key="/datasets/explorer2"
          render={props => {
            return (
              <Suspense fallback={<NavDataLoading />}>
                <CayleyExplorer2
                  history={history}
                  collapseNav={collapseNav}
                  openNav={openNav}
                  collapseAppStatus={collapseAppStatus}
                  openAppStatus={openAppStatus}
                  {...props}
                />
              </Suspense>
            )
          }}
        />
        <Route
          exact
          path="/datasets/explorer2/:id"
          key="/datasets/explorer2/:id"
          render={props => {
            return (
              <Suspense fallback={<NavDataLoading />}>
                <CayleyExplorer2
                  history={history}
                  collapseNav={collapseNav}
                  openNav={openNav}
                  collapseAppStatus={collapseAppStatus}
                  openAppStatus={openAppStatus}
                  {...props}
                />
              </Suspense>
            )
          }}
        />

        <Route
          exact
          path="/datasets/detail/:id/groups/:groupid"
          key="/datasets/detail/:id/groups/:groupid"
          render={props => (
            <Suspense fallback={<NavDataLoading />}>
              <DatasetDetail {...props} />
            </Suspense>
          )}
        />
        <Route
          exact
          path="/datasets/detail/:id/groups"
          key="/datasets/detail/:id/groups"
          render={props => (
            <Suspense fallback={<NavDataLoading />}>
              <DatasetDetail {...props} />
            </Suspense>
          )}
        />
        <Route
          exact
          path="/datasets/detail/:id"
          key="/datasets/detail/:id"
          render={props => (
            <Suspense fallback={<NavDataLoading />}>
              <DatasetDetail {...props} />
            </Suspense>
          )}
        />

        {services && services.studio && services.studio.renoir && (
          <Route
            exact
            path={'/datasets/items/:id/annotate'}
            key={'/datasets/items/:id/annotate'}
            render={props => {
              const getRouterParamId = () => {
                console.log('getRouterParamId id ', props)
                const {
                  match: {
                    params: { id }
                  }
                } = props
                return id || null
              }
              return (
                <ExternalLinkIframe
                  name={'annotate'}
                  title={'Annotate'}
                  history={history}
                  link={services.studio.renoir + '?dataitemId=' + getRouterParamId()}
                  collapseNav={collapseNav}
                  openNav={openNav}
                  collapseAppStatus={collapseAppStatus}
                  openAppStatus={openAppStatus}
                />
              )
            }}
          />
        )}
        {props.paths &&
          props.paths.map(feature => {
            if (feature.external) {
              return (
                <Route
                  exact
                  path={'/' + feature.name}
                  key={'/' + feature.name}
                  render={props => {
                    return (
                      <ExternalLinkIframe
                        name={feature.name}
                        title={feature.title}
                        history={history}
                        link={feature.link}
                        collapseNav={collapseNav}
                        openNav={openNav}
                        collapseAppStatus={collapseAppStatus}
                        openAppStatus={openAppStatus}
                      />
                    )
                  }}
                />
              )
            }
          })}
        {props.paths &&
          props.paths.map(feature => {
            if (feature.external && feature.name === 'studio') {
              return (
                <Route
                  exact
                  path={'/' + feature.name + '/:id'}
                  key={'/' + feature.name + '/:id'}
                  render={props => {
                    const getRouterParamId = () => {
                      console.log('getRouterParamId id ', props)
                      const {
                        match: {
                          params: { id }
                        }
                      } = props
                      return id || null
                    }
                    return (
                      <ExternalLinkIframe
                        name={feature.name}
                        title={feature.title}
                        history={history}
                        link={feature.link + '?appId=' + getRouterParamId()}
                        collapseNav={collapseNav}
                        openNav={openNav}
                        collapseAppStatus={collapseAppStatus}
                        openAppStatus={openAppStatus}
                      />
                    )
                  }}
                />
              )
            }
          })}
        {/* <Route exact path="/build/:id" component={BuildAppTemplate} /> */}
        {props.defaultPath && <Redirect from="*" to={props.defaultPath} key="default-route" />}
      </Switch>
    </div>
  )
}

class Dashboard extends React.Component {
  constructor() {
    super()
    this.state = { activeItem: null, path: null, defaultPath: null, navCollapse: false, appStatusCollapse: true }
  }

  componentDidMount() {
    this.fetchDatoinServices()
    this.fetchMyProfile()
    this.fetchHelpLinks()
  }

  getPath = () => {
    return this.props.location.pathname
  }

  fetchDatoinServices() {
    const api = this.props.api
    //Don't do anything if api does not exist.
    if (api == null) return
    const action = api.createFetchDatoinservicesRSAA()
    this.props.dispatch(action)
  }

  fetchMyProfile() {
    const api = this.props.api
    //Don't do anything if api does not exist.
    if (api == null) return
    const action = api.createFetchMyProfileRSAA()
    this.props.dispatch(action)
  }

  fetchHelpLinks() {
    const api = this.props.api
    //Don't do anything if api does not exist.
    if (api == null) return
    const action = api.createFetchHelpLinksRSAA()
    Promise.resolve(this.props.dispatch(action)).then(response => {
      console.log('fetchHelpLinks response ', response)
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.api !== prevProps.api) {
      this.fetchDatoinServices()
      this.fetchMyProfile()
      this.fetchHelpLinks()
    }

    console.log('getPath ', this.getPath())
    const path = this.getPath()
    if (prevProps.myProfile !== this.props.myProfile || this.state.path !== path) {
      this.props.myProfile.allowedFeatures.map(feature => {
        if (path.startsWith(feature.link) || path.startsWith('/' + feature.name)) {
          console.log('activeItem ', feature.name)
          this.setState({
            activeItem: feature,
            path: path
          })
        }
      })
    }

    if (prevProps.myProfile !== this.props.myProfile && this.props.myProfile.allowedFeatures) {
      const firstPath = this.props.myProfile.allowedFeatures.find(mprof => !mprof.external)
      const hasAppStore = this.props.myProfile.allowedFeatures.find(prof => prof.link === '/app-store')
      let defaultPath = firstPath === undefined ? null : firstPath.link
      console.log('firstPath ', firstPath, ' defaultPath ', defaultPath)
      if (this.props.myProfile.isDebut && hasAppStore !== undefined) {
        defaultPath = '/app-store'
      }

      this.setState({
        defaultPath: defaultPath
      })
    }
  }

  onNavCollapse = () => {
    this.setState({ navCollapse: !this.state.navCollapse })
  }

  collapseNav = () => {
    this.setState({ navCollapse: true })
  }

  openNav = () => {
    this.setState({ navCollapse: false })
  }

  onAppStatusCollapse = () => {
    this.setState({ appStatusCollapse: !this.state.appStatusCollapse })
  }

  collapseAppStatus = () => {
    this.setState({ appStatusCollapse: false })
  }

  openAppStatus = () => {
    this.setState({ appStatusCollapse: true })
  }

  render() {
    const { myProfile } = this.props

    return (
      <React.Fragment>
        {/* <button
          onClick={e => {
            this.props.dispatch(notificationCenterAddTaskCreator(new CountdownTask()))
          }}
          style={{ position: 'fixed', zIndex: 999999, fontSize: 24, bottom: 12, left: 12 }}
        >
          test
        </button> */}
        <Suspense fallback={<NavDataLoading />}>
          <SignInRequired>
            <VerticalNavigation
              items={myProfile.allowedFeatures}
              activeItem={this.state.activeItem}
              history={this.props.history}
              onNavCollapse={this.onNavCollapse}
              collapse={this.state.navCollapse}
              myProfile={this.props.myProfile}
            />
            <MainContent
              paths={myProfile.allowedFeatures}
              services={this.props.datoinServices}
              error={this.props.apiError}
              defaultPath={this.state.defaultPath}
              history={this.props.history}
              collapseNav={this.collapseNav}
              openNav={this.openNav}
              collapseAppStatus={this.collapseAppStatus}
              openAppStatus={this.openAppStatus}
            />
            {/* <NotificationCenter /> */}
            <ToastNotification />

            {this.props.myProfile &&
              this.props.myProfile.roles &&
              this.props.myProfile.roles.toString().toUpperCase().includes('APPSTATUSWINDOW') &&
              this.props.bgStatus.statusList && (
                <AppStatus
                  appStatus={this.state.appStatusCollapse}
                  collapseAppStatus={this.onAppStatusCollapse}
                  openAppStatus={this.openAppStatus}
                />
              )}
          </SignInRequired>
        </Suspense>
      </React.Fragment>
    )
  }
}

Dashboard.propTypes = {
  api: PropTypes.object,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  api: state.api,
  apiError: state.apiError,
  myProfile: state.myProfile,
  myHelpLinks: state.myHelpLinks,
  datoinServices: state.datoinServices,
  bgStatus: state.bgStatus
})

export default withRouter(connect(mapStateToProps)(Dashboard))
