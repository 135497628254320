import { WEBSOCKET_MESSAGE } from '@giantmachines/redux-websocket'
import API from '../Api.js'
import actionTypes from './actionTypes.js'

export function apiError(state = { message: null }, action) {
  if (action.type.endsWith('_REQUEST') || action.type.endsWith('_SUCCESS')) {
    return { message: null }
  }
  if (action.type.endsWith('_FAILURE')) {
    return { ...action.payload.response }
  }
  return state
}

export function ws(state = {}, action) {
  if (action.type === WEBSOCKET_MESSAGE) {
    return { ...action.payload }
  }
  return state
}

export function api(state = null, action) {
  if (action.type === actionTypes.INIT_API_REQUEST) {
    return new API({
      apiRootUrl: `${process.env.REACT_APP_GATEWAY_API_URL}`,
      fileRootUrl: `${process.env.REACT_APP_GATEWAY_FILE_URL}`,
      wssUrl: `${process.env.REACT_APP_GATEWAY_WEB_SOCKET_URL}`
    })
  }
  return state
}

export function myApps(
  state = {
    isLoading: true,
    isLoadingNoScroll: true,
    error: null,
    docs: [],
    pageInfo: {
      start: 0,
      max: 10,
      query: null,
      filterQuery: null,
      total: 0,
      nextStart: 0,
      sort: null,
      sortOrder: null,
      statusFilter: []
    },
    searchFacets: { tags: [], status: [] }
  },
  action
) {
  switch (action.type) {
    case actionTypes.FETCH_MY_APPS_REQUEST:
      return { ...state, isLoading: action.meta.append ? false : true }
    case actionTypes.FETCH_MY_APPS_SUCCESS: {
      if (action.meta.append) {
        let previousDocs = state.docs
        return Object.assign(action.payload, {
          isLoading: false,
          error: null,
          docs: previousDocs.concat(action.payload.docs)
        })
      }
      return Object.assign(action.payload, { isLoading: false, error: null })
    }
    case actionTypes.FETCH_MY_APPS_FAILURE:
      return { ...state, isLoading: action.meta.append ? false : true, error: action.payload }
    case actionTypes.DELETE_APP_SUCCESS: {
      var updatedDocs = state.docs.filter(app => app.id !== action.meta.id)
      return { ...state, docs: updatedDocs }
    }
  }
  return state
}

export function myProfile(state = { isLoading: false, allowedFeatures: [] }, action) {
  switch (action.type) {
    case actionTypes.FETCH_MY_PROFILE_REQUEST:
      return { isLoading: true, allowedFeatures: [] }
    case actionTypes.FETCH_MY_PROFILE_SUCCESS:
      return Object.assign(action.payload, { isLoading: false })
    case actionTypes.FETCH_MY_PROFILE_FAILURE:
      return { isLoading: false, allowedFeatures: [] }
  }
  return state
}

export function myHelpLinks(state = { isLoading: false, helpLinks: [] }, action) {
  switch (action.type) {
    case actionTypes.FETCH_HELP_LINKS_REQUEST:
      return { isLoading: true, helpLinks: [] }
    case actionTypes.FETCH_HELP_LINKS_SUCCESS: {
      console.log('myHelpLinks action.payload ', action.payload)
      return Object.assign({ helpLinks: action.payload, isLoading: false })
    }
    case actionTypes.FETCH_HELP_LINKS_FAILURE:
      return { isLoading: false, helpLinks: [] }
  }
  return state
}

export function itrSampleData(state = { isLoading: false, iterations: {} }, action) {
  switch (action.type) {
    case actionTypes.FETCH_ITR_SAMPLE_DATA_REQUEST: {
      state.iterations[action.meta.iterId] = { isLoading: true }
      return { ...state, iterations: state.iterations }
    }
    case actionTypes.FETCH_ITR_SAMPLE_DATA_SUCCESS: {
      state.iterations[action.meta.iterId] = Object.assign(action.payload, {
        isLoading: false
      })
      return { ...state, iterations: state.iterations }
    }
    case actionTypes.FETCH_ITR_SAMPLE_DATA_FAILURE: {
      state.iterations[action.meta.iterId] = { isLoading: false }
      return { ...state, iterations: state.iterations }
    }
  }
  return state
}

export function datasetSchemas(state = { isLoading: false, datasetSchemas: {} }, action) {
  switch (action.type) {
    case actionTypes.FETCH_DATASET_SCHEMA_REQUEST: {
      state.datasetSchemas[action.meta.id] = { isLoading: true }
      return { ...state, datasetSchemas: state.datasetSchemas }
    }
    case actionTypes.FETCH_DATASET_SCHEMA_SUCCESS: {
      if (action.payload) {
        state.datasetSchemas[action.meta.id] = Object.assign(action.payload, {
          isLoading: false
        })
      }
      return { ...state, datasetSchemas: state.datasetSchemas }
    }
    case actionTypes.FETCH_DATASET_SCHEMA_FAILURE: {
      state.datasetSchemas[action.meta.id] = { isLoading: false }
      return { ...state, datasetSchemas: state.datasetSchemas }
    }
  }
  return state
}

export function modelDetails(state = { isLoading: false, modelDetails: {} }, action) {
  switch (action.type) {
    case actionTypes.FETCH_MODEL_DETAIL_REQUEST: {
      state.modelDetails[action.meta.id] = { isLoading: true }
      return { ...state, modelDetails: state.modelDetails }
    }
    case actionTypes.FETCH_MODEL_DETAIL_SUCCESS: {
      if (action.payload) {
        state.modelDetails[action.meta.id] = Object.assign(action.payload, {
          isLoading: false
        })
      }
      return { ...state, modelDetails: state.modelDetails }
    }
    case actionTypes.FETCH_MODEL_DETAIL_FAILURE: {
      state.modelDetails[action.meta.id] = { isLoading: false }
      return { ...state, modelDetails: state.modelDetails }
    }
  }
  return state
}

export function itrSampleModel(state = { isLoading: false, iterations: {} }, action) {
  switch (action.type) {
    case actionTypes.FETCH_ITR_SAMPLE_MODEL_REQUEST: {
      state.iterations[action.meta.iterId] = { isLoading: true }
      return { ...state, iterations: state.iterations }
    }
    case actionTypes.FETCH_ITR_SAMPLE_MODEL_SUCCESS: {
      state.iterations[action.meta.iterId] = Object.assign(action.payload, {
        isLoading: false
      })
      return { ...state, iterations: state.iterations }
    }
    case actionTypes.FETCH_ITR_SAMPLE_MODEL_FAILURE: {
      state.iterations[action.meta.iterId] = { isLoading: false }
      return { ...state, iterations: state.iterations }
    }
  }
  return state
}

export function datoinServices(state = { isLoading: false, studio: {} }, action) {
  switch (action.type) {
    case actionTypes.FETCH_WELLKNOWN_REQUEST:
      return { isLoading: true }
    case actionTypes.FETCH_WELLKNOWN_SUCCESS:
      return Object.assign(action.payload, { isLoading: false })
    case actionTypes.FETCH_WELLKNOWN_FAILURE:
      return { isLoading: false }
  }
  return state
}

export function appDetail(state = { isLoading: false, error: null, id: null, iterations: [] }, action) {
  switch (action.type) {
    case actionTypes.SILENTLY_FETCH_APP_DETAIL_REQUEST:
      return { ...state, isLoading: false }
    case actionTypes.FETCH_APP_DETAIL_REQUEST:
      return { isLoading: true, error: null, id: null, iterations: [] }
    case actionTypes.SILENTLY_FETCH_APP_DETAIL_SUCCESS:
    case actionTypes.FETCH_APP_DETAIL_SUCCESS:
      return { ...action.payload, error: null, isLoading: false }
    case actionTypes.SILENTLY_FETCH_APP_DETAIL_FAILURE:
      return { ...state, isLoading: false, error: action.payload }
    case actionTypes.FETCH_APP_DETAIL_FAILURE:
      return { ...state, error: action.payload, isLoading: false }
  }
  return state
}

export function appItreationDetail(state = { isLoading: false, error: null, id: null, iterations: [] }, action) {
  switch (action.type) {
    case actionTypes.FETCH_APP_ITERATION_DETAIL_SILENTLY_REQUEST:
      return { ...state, isLoading: false }
    case actionTypes.FETCH_APP_ITERATION_DETAIL_OPENLY_REQUEST:
      return { isLoading: true, error: null, id: null, iterations: [] }
    case actionTypes.FETCH_APP_ITERATION_DETAIL_SILENTLY_SUCCESS:
    case actionTypes.FETCH_APP_ITERATION_DETAIL_OPENLY_SUCCESS:
      return { ...action.payload, error: null, isLoading: false }
    case actionTypes.FETCH_APP_ITERATION_DETAIL_SILENTLY_FAILURE:
      return { ...state, isLoading: false, error: action.payload }
    case actionTypes.FETCH_APP_ITERATION_DETAIL_OPENLY_FAILURE:
      return { ...state, error: action.payload, isLoading: false }
  }
  return state
}

export function appDetailQuickSetting(state = { isLoading: false, error: null, id: null }, action) {
  switch (action.type) {
    case actionTypes.FETCH_APP_QUICK_SETTING_REQUEST:
      return { isLoading: true, error: null, id: null }
    case actionTypes.FETCH_APP_QUICK_SETTING_SUCCESS:
      return { ...action.payload, error: null, isLoading: false }
    case actionTypes.FETCH_APP_QUICK_SETTING_FAILURE:
      return { ...state, error: action.payload, isLoading: false }
  }
  return state
}

export function startAppJob(state = { isLoading: false, error: null }, action) {
  switch (action.type) {
    case actionTypes.START_APP_JOB_REQUEST:
      return { ...state, isLoading: true }
    case actionTypes.START_APP_JOB_SUCCESS:
      return { ...action.payload, isLoading: false, error: null }
    case actionTypes.START_APP_JOB_FAILURE:
      return { ...state, isLoading: false, error: action.payload }
  }
  return state
}

export function stopAppJob(state = { isLoading: false, error: null }, action) {
  switch (action.type) {
    case actionTypes.STOP_APP_JOB_REQUEST:
      return { ...state, isLoading: true }
    case actionTypes.STOP_APP_JOB_SUCCESS:
      return { ...action.payload, isLoading: false, error: null }
    case actionTypes.STOP_APP_JOB_FAILURE:
      return { ...state, isLoading: false, error: action.payload }
  }
  return state
}

export function appSandboxRun(state = { isLoading: false, result: null, error: null }, action) {
  switch (action.type) {
    case actionTypes.APP_SANDBOX_RUN_REQUEST:
      return { ...state, isLoading: true }

    case actionTypes.APP_SANDBOX_RUN_SUCCESS:
      return { error: null, result: action.payload, isLoading: false }

    case actionTypes.APP_SANDBOX_RUN_FAILURE:
      return {
        result: null,
        error: action.payload,
        isLoading: false
      }
    case actionTypes.RESET_APP_SANDBOX_RUN_REQUEST:
      return { result: null, error: null, isLoading: false }
  }
  return state
}

export function appQuickSettings(state = { isLoading: false, result: null, error: null }, action) {
  switch (action.type) {
    case actionTypes.APP_QUICK_SETTINGS_REQUEST:
      return { ...state, isLoading: true }

    case actionTypes.APP_QUICK_SETTINGS_SUCCESS:
      return { error: null, result: action.payload, isLoading: false }

    case actionTypes.APP_QUICK_SETTINGS_FAILURE: {
      return {
        result: null,
        error: action.payload,
        isLoading: false
      }
    }

    case actionTypes.RESET_APP_QUICK_SETTINGS_REQUEST:
      return { result: null, error: null, isLoading: false }
  }
  return state
}
export function buildApp(state = { isLoading: false, result: null, error: null }, action) {
  console.log('action.type ', action)
  switch (action.type) {
    case actionTypes.BUILD_APP_TEMPLATE_REQUEST:
      return { ...state, isLoading: true }

    case actionTypes.BUILD_APP_TEMPLATE_SUCCESS:
      return { error: null, result: action.payload, isLoading: false }

    case actionTypes.BUILD_APP_TEMPLATE_FAILURE: {
      console.log('action.payload ', action.payload)
      return {
        result: null,
        error: action.payload,
        isLoading: false
      }
    }
  }
  return state
}
export function userMetaData(state = { isLoading: false, result: null, error: null }, action) {
  switch (action.type) {
    case actionTypes.CUSTOM_USER_META_REQUEST:
      return { ...state, isLoading: true }

    case actionTypes.CUSTOM_USER_META_SUCCESS:
      return { error: null, result: action.payload, isLoading: false }

    case actionTypes.CUSTOM_USER_META_FAILURE:
      return {
        result: null,
        error: action.payload,
        isLoading: false
      }
  }
  return state
}

export function deploymentProfiles(state = { isLoading: false, profiles: [], error: null }, action) {
  switch (action.type) {
    case actionTypes.FETCH_DEPLOYMENT_PROFILE_SCHEMAS_REQUEST:
      return { ...state, isLoading: true }
    case actionTypes.FETCH_DEPLOYMENT_PROFILE_SCHEMAS_SUCCESS:
      return { profiles: action.payload, isLoading: false, error: null }
    case actionTypes.FETCH_DEPLOYMENT_PROFILE_SCHEMAS_FAILURE:
      return { profiles: [], error: action.payload, isLoading: false }
  }
  return state
}

export function myTemplates(
  state = {
    docs: [],
    pageInfo: {
      total: 0,
      max: 0,
      start: 0,
      nextStart: 0
    },
    start: 0,
    max: 20,
    total: 0,
    nextStart: 0,
    query: '',
    isLoading: true,
    tag: [],
    status: [],
    sort: 'updatedOn',
    sortOrder: 'asc'
  },
  action
) {
  switch (action.type) {
    case actionTypes.FETCH_TEMPLATES_REQUEST:
      return { ...state, isLoading: action.meta.append ? false : true }
    case actionTypes.FETCH_TEMPLATES_SUCCESS: {
      if (action.meta.append) {
        let previousDocs = state.docs
        return Object.assign(action.payload, {
          isLoading: false,
          docs: previousDocs.concat(action.payload.docs)
        })
      }
      return Object.assign(action.payload, { isLoading: false })
    }
    case actionTypes.FETCH_TEMPLATES_FAILURE:
      return { ...state, isLoading: action.meta.append ? false : true }
  }
  return state
}

export function myModels(state = { isLoading: false, error: null, docs: [] }, action) {
  switch (action.type) {
    case actionTypes.FETCH_MY_MODELS_REQUEST:
      return { ...state, isLoading: true }
    case actionTypes.FETCH_MY_MODELS_SUCCESS:
      return { ...action.payload, isLoading: false }
    case actionTypes.FETCH_MY_MODELS_FAILURE:
      return { ...state, error: action.payload, isLoading: false }
  }
  return state
}

export function myReccTemplates(
  state = {
    docs: [],
    pageInfo: {
      total: 0,
      max: 0,
      start: 0,
      nextStart: 0
    },
    isLoading: false
  },
  action
) {
  switch (action.type) {
    case actionTypes.FETCH_RECC_TEMPLATES_REQUEST:
      return { ...state, isLoading: true }
    case actionTypes.FETCH_RECC_TEMPLATES_SUCCESS:
      return Object.assign(action.payload, { isLoading: false })
    case actionTypes.FETCH_RECC_TEMPLATES_FAILURE:
      return { ...state, isLoading: false }
  }
  return state
}

export function myDatasets(state = { isLoading: false, error: null, docs: [] }, action) {
  console.log('myDatasets action', action)
  switch (action.type) {
    case actionTypes.FETCH_MY_DATASETS_REQUEST:
      return { ...state, error: null, isLoading: true }
    case actionTypes.FETCH_MY_DATASETS_SUCCESS:
      return { ...action.payload, error: null, isLoading: false }
    case actionTypes.FETCH_MY_DATASETS_FAILURE:
      return { ...state, error: action.payload, isLoading: false }
  }
  return state
}
export function datasetDetail(state = { isLoading: false, error: null, docs: [] }, action) {
  switch (action.type) {
    case actionTypes.FETCH_DATASET_DETAIL_REQUEST:
      return { ...state, error: null, isLoading: true }
    case actionTypes.FETCH_DATASET_DETAIL_SUCCESS:
      return { ...action.payload, error: null, isLoading: false }
    case actionTypes.FETCH_DATASET_DETAIL_FAILURE:
      return { ...state, error: action.payload, isLoading: false }
  }
  return state
}

export function myDatasetsEmbed(state = { isLoading: false, error: null, docs: [] }, action) {
  switch (action.type) {
    case actionTypes.FETCH_MY_DATASETS_EMBED_REQUEST:
      return { ...state, error: null, isLoading: true }
    case actionTypes.FETCH_MY_DATASETS_EMBED_SUCCESS:
      return { ...action.payload, error: null, isLoading: false }
    case actionTypes.FETCH_MY_DATASETS_EMBED_FAILURE:
      return { ...state, error: action.payload, isLoading: false }
  }
  return state
}
export function datasetDetailEmbed(state = { isLoading: false, error: null, docs: [] }, action) {
  switch (action.type) {
    case actionTypes.FETCH_DATASET_DETAIL_EMBED_REQUEST:
      return { ...state, error: null, isLoading: true }
    case actionTypes.FETCH_DATASET_DETAIL_EMBED_SUCCESS:
      return { ...action.payload, error: null, isLoading: false }
    case actionTypes.FETCH_DATASET_DETAIL_EMBED_FAILURE:
      return { ...state, error: action.payload, isLoading: false }
  }
  return state
}

export function datasetGroupDetail(state = { isLoading: false, error: null, docs: [] }, action) {
  switch (action.type) {
    case actionTypes.FETCH_DATASET_GROUP_DETAIL_REQUEST:
      return { ...state, error: null, isLoading: true }
    case actionTypes.FETCH_DATASET_GROUP_DETAIL_SUCCESS:
      return { ...action.payload, error: null, isLoading: false }
    case actionTypes.FETCH_DATASET_GROUP_DETAIL_FAILURE:
      return { ...state, error: action.payload, isLoading: false }
  }
  return state
}

export function appSettingsRaw(state = { content: null, isLoading: true, isLarge: false, error: null }, action) {
  switch (action.type) {
    case actionTypes.FETCH_SETTINGS_CONTENT_REQUEST:
      return { ...state, content: null, isLoading: true }
    case actionTypes.FETCH_SETTINGS_CONTENT_SUCCESS:
      return Object.assign(action.payload, { isLoading: false })
    case actionTypes.FETCH_SETTINGS_CONTENT_FAILURE:
      if (action.payload.status === 413) {
        return { ...state, isLoading: false, isLarge: true }
      } else {
        return { ...state, isLoading: false, error: action.payload }
      }
  }
  return state
}

export function appSettings(state = { resources: [], isLoading: false, error: null }, action) {
  switch (action.type) {
    case actionTypes.FETCH_APP_SETTINGS_REQUEST:
      return { ...state, resources: [], isLoading: true }
    case actionTypes.SILENTLY_FETCH_APP_SETTINGS_REQUEST:
      return { ...state, resources: [], isLoading: false }
    case actionTypes.FETCH_APP_SETTINGS_SUCCESS:
      return Object.assign(action.payload, { isLoading: false })
    case actionTypes.SILENTLY_FETCH_APP_SETTINGS_SUCCESS:
      return Object.assign(action.payload, { isLoading: false })
    case actionTypes.FETCH_APP_SETTINGS_FAILURE:
      return { ...state, isLoading: false, error: action.payload }
    case actionTypes.SILENTLY_FETCH_APP_SETTINGS_FAILURE:
      return { ...state, isLoading: false, error: action.payload }
  }
  return state
}

export function qsDataSetItemSchema(state = { isLoading: true, error: null }, action) {
  switch (action.type) {
    case actionTypes.FETCH_DATASET_ITEM_SCHEMA_REQUEST:
      return { ...state, isLoading: true }
    case actionTypes.FETCH_DATASET_ITEM_SCHEMA_SUCCESS:
      return Object.assign(action.payload, { isLoading: false })
    case actionTypes.FETCH_DATASET_ITEM_SCHEMA_FAILURE:
      return { ...state, isLoading: false, error: action.payload }
  }
  return state
}

export function cayleyExplorerData(
  state = { isLoading: true, error: null, hasError: false, sample: null, result: null, isInvalid: false },
  action
) {
  switch (action.type) {
    case actionTypes.FETCH_EXPLORE_EXECUTE_REQUEST:
      return { ...state, isLoading: true, sample: null, result: null, error: null }
    case actionTypes.FETCH_EXPLORE_EXECUTE_SUCCESS: {
      if (action.meta && action.meta !== undefined && action.meta.finalized) {
        return { ...state, isLoading: false, result: action.payload, error: null }
      } else {
        return Object.assign({ isLoading: false, sample: action.payload, result: null, error: null })
      }
    }
    case actionTypes.FETCH_EXPLORE_EXECUTE_FAILURE: {
      let inValid = action.payload && action.payload.status === 400
      return { ...state, isLoading: false, sample: null, result: null, error: action.payload, isInvalid: inValid }
    }
  }
  return state
}

export function cayleyPreviewerData(
  state = { isLoading: false, error: null, hasError: false, sample: null, isInvalid: false },
  action
) {
  switch (action.type) {
    case actionTypes.FETCH_EXPLORE_PREVIEW_REQUEST:
      return { ...state, isLoading: true, sample: null, error: null, hasError: false }
    case actionTypes.FETCH_EXPLORE_PREVIEW_SUCCESS: {
      return Object.assign({ isLoading: false, sample: action.payload, error: null, hasError: false })
    }
    case actionTypes.FETCH_EXPLORE_PREVIEW_FAILURE: {
      console.log('action.meta ', action.meta)

      let inValid = action.payload && action.payload.status === 400
      return { ...state, isLoading: false, sample: null, error: action.payload, isInvalid: inValid, hasError: true }
    }
  }
  return state
}

export function cancelUploadingDataSet(state = { isDeleted: false, error: null }, action) {
  switch (action.type) {
    case actionTypes.DELETE_DATASET_TUSIO_REQUEST:
      return { ...state, isDeleted: false }
    case actionTypes.DELETE_DATASET_TUSIO_FAILURE:
      return { ...state, isDeleted: false, error: action.payload }
    case actionTypes.DELETE_DATASET_TUSIO_SUCCESS: {
      return { ...state, isDeleted: true }
    }
  }
  return state
}
