import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import Dashboard from './Dashboard'
import store from './store'

function Loading(props) {
  return (
    <div className="centreLoadingSpinner" style={{ height: 'calc( 100vh - 50px )' }}>
      <div className="spnner-xb">
        <div className="spnner-xb-1" />
        <div className="spnner-xb-2" />
        <div className="spnner-xb-3" />
        <div className="spnner-xb-4" />
        <div className="spnner-xb-5" />
        <div className="spnner-xb-6" />
        <div className="spnner-xb-7" />
        <div className="spnner-xb-8" />
        <div className="spnner-xb-9" />
      </div>
    </div>
  )
}

ReactDOM.render(
  <Provider store={store}>
    <Router>
      {/* <Suspense fallback={<Loading />}> */}
      <Dashboard />
      {/* </Suspense> */}
    </Router>
  </Provider>,
  document.getElementById('root')
)
